.gallery-container {
  margin: 0 auto;
}
.gallery-container-controls {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.6);
  padding-top: 20px;
  height: 253px;
  position: relative;
  display: none;
  background: #fff;
}
.gallery-container-controls .slidesjs-container {
  width: calc(100% - 40px) !important;
  margin-left: 20px;
}
.gallery-container-controls .slidesjs-container .slidesjs-control {
  width: 100%;
}
.gallery-container-controls .slidesjs-container .slidesjs-control a {
  width: 100%;
}
.gallery-container-controls .slidesjs-container .slidesjs-control a img {
  width: 90%;
  position: relative;
  top: -46px;
  left: -19px;
}
.gallery-container-controls .slidesjs-previous {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 132px;
  width: 50px;
  background: rgba(255, 255, 255, 0.7);
  transition: all .3s;
  z-index: 15;
  font-family: lato;
  font-size: 55px;
  text-decoration: none;
  color: #fff;
  padding-top: 60px;
  opacity: 0.5; }
 .gallery-container-controls .slidesjs-previous:hover {
        opacity: 1; }
.gallery-container-controls .slidesjs-next {
  position: absolute;
  top: 20px;
  right: 19px;
  height: 132px;
  font-family: lato;
  font-size: 55px;
  text-decoration: none;
  color: #fff;
  padding-top: 60px;
  width: 50px;
  background: rgba(255, 255, 255, 0.7);
  transition: all .3s;
  z-index: 15;
  opacity: 0.5; }
.gallery-container-controls .slidesjs-next:hover {
  opacity: 1; }
.gallery-container-controls .slidesjs-pagination {
  padding: 0;
  display: inline-block; }
.gallery-container-controls .slidesjs-pagination li {
  display: inline-block;
  border: solid #777777 2px;
  margin: 10px;
  width: 10px;
  height: 10px; }
.gallery-container-controls .slidesjs-pagination li a {
  display: inline-block;
  width: 10px;
  height: 10px;
  text-indent: -99999px; }
.gallery-container-controls .slidesjs-pagination li a.active {
  background: #777777; }
.slidesjs-pagination .active {
  color: red; }
