.social{

  &__panel{
  width:200px;
  display: flex;
  align-items: center;
  justify-content: space-between;




  @include laptop{
    width: 200px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        order: 3;

  }

  @include tablet{
    width: 200px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
        order: 3;

  }

  @include mobile{
    width: 200px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    order: 3;

  }

  &.search-mode{
    width: 240px;
    transition: all .3s .6s;

    .social__link--facebook{
      width: 0;
      height: 33px;
      display: inline-block;
      background: url(../_gfx/fb_icon.png);
      transform:scale(0);
      transition: transform .2s ,width .2s .4s ;
    }

    .social__link--youtube{
      width: 0;
      height: 27px;
      display: inline-block;
      background: url(../_gfx/yt_icon.png);
      transform:scale(0);
      transition: transform .2s .2s ,width .2s .4s ;
        }

        .social__link--search{
        position: relative;
        top: 5px;

        background-size: 60%;
        cursor: pointer;
        }

    .social__link--search__input{
      width: calc(100% - 60px);
      opacity: 1;
      border-bottom: 3px #000 solid;
      padding: 0;
      height: 42px;
      border: none;
      border-bottom: 3px #a86e35 solid;
      transition: all .2s .3s;
      top: -13px;
      position: relative;
      font-size: 24px;
      color: #d2d2d2;
      margin-right: 0px;
      outline: none;
      left:3px;

      &:focus{
        color: #a86e35;
      }
    }


    }

  }

  &__link{

    &--facebook{
      width: 17px;
      height: 33px;
      display: inline-block;
      background: url(../_gfx/fb_icon.png);

      &:hover{
        opacity: .6;
      }


    }

    &--youtube{
      width: 48px;
      height: 27px;
      display: inline-block;
      background: url(../_gfx/yt_icon.png);

      &:hover{
        opacity: .6;
      }

        }

    &--search{
      width: 42px;
      height: 42px;
      display: inline-block;
      background: url(../_gfx/search_icon.png) center no-repeat;
      border: 0;
      position: relative;
      top:6px;
      transition: all .2s;

      &:hover{
        opacity: .6;
      }



      &__input{
        width: 0;
        opacity: 1;
        border-bottom: 3px #000 solid;
        padding: 0;
        height: 38px;
        border: none;
        border-bottom: 3px #a86e35 solid;
      }

    }
  }
}


.history-1{
  background: #cc9e62;
    color: #fff;
    padding-left: calc((100% - 1200px) / 2);
    box-sizing: border-box;
    padding-bottom: 30%;
    background-image: url(../_gfx/subsite/image-7.jpg);
    background-position: center bottom;

    p{
      color: #fff;
    }
}
