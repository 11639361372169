#fwslider{
  @include mobile{
    height:400px !important;
    padding-top:30%;
  }
  @include tablet{
    height:500px !important;
  }
  .slide_content_wrap{
    width:100%;
    position: relative;
    top:166px;
    overflow: hidden;
    max-width: 1200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .title__block{
      margin-bottom: 42px;

    }
    hr{
      margin-bottom: 45px;
      width: 202px;
      float: left;
      display: inline-block;
    }
    .desc__block{
      box-sizing: border-box;
      float: left;
      p{
        color: #fff;
      }
    }
  }
  .controls{
    &__holder{
      position: absolute;
          bottom: 142px;
          width: 50%;
          margin-left: 0;
          text-align: center;
          margin-left: 50%;
          @include tablet{
            display: none;
          }
          @include mobile{
            display: none;
          }

    }
  }
  .contact{
    &__details{
      width: 600px;
    display: inline-block;
    position: absolute;
    z-index: 20;
    top: 520px;
    color: $primary_color;
    text-align: left;
    border: solid 3px $primary_color;
    box-sizing: border-box;
    padding: 30px 30px 50px 30px;
border-bottom: none;
overflow: hidden;
    p{
      color: #fff;
      strong{
        color: $primary_color;
      }
    }
    .open{
      position: absolute;
      bottom:0px;
      height: 3px;
      right:0;
      border-left: 300px solid $primary_color;
      border-right: 60px solid $primary_color;
      display: inline;
      width: 360px;
    text-align: center;
      p{
        font-size: 24px;
        position: relative;
        right:0;
        top: -50px;
      }
      &::before{

      }
      &::after{

      }
    }
    }
  }
  .slider_container{
    .slide{
      @include mobile{
        width:100%;
        height:550px;
      }
      &_content{
        @include mobile{
          width: 100%;
        }
        &_wrap{
          div{
            &:nth-of-type(1){
              img{
                width:100%;
              }
            }
            &:nth-of-type(2){
              img{

              }
            }
          }
        }
      }
    }
  }
}

.subpage-top{
      margin-top: 187px;
      height: 134px;
      width: 100%;
      position: relative;
      &::before{
        content: '';
        width:276px;
        height:236px;
        position: absolute;
        left:50%;
        z-index: 1000;
        top:-80px;
        transform: translateX(-50%);
        background-size: auto 100%;
        transition: all .4s;
        @include tablet{
          width: 205px;
          height: 189px;
          left: 37%;
          top: -45px;
        }
        @include mobile{
          height:120px;
          width:146px;
          top:20px;
          z-index: 1;
        }
      }
      &.scrolled{
        &::before{
          top:-60px;
          opacity: 0;
          @include tablet{
            top:90px;
          }
          @include mobile{
            top:50px;
          }
        }
      }
      &::after{
        content: '';
        position: absolute;
        width: 100%;
        height:20px;
        bottom:0;
        left:0;
      }

}
