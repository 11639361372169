.block{
  &--wino{
    img{
    width: 160%;
    position: relative;
    left: -30%;
    top: 20px;
    text-align: center;
    @include mobile{
      top: 0px;
      }
    }
    @include mobile{
    margin-bottom: 30px;
    }
  }
}
.block{
  &wino{
    img{
    width: 160%;
    position: relative;
    left: -30%;
    top: 20px;
    text-align: center;
    @include mobile{
      top: 0px;
      }   
    }
    @include mobile{
    margin-bottom: 30px;
    }
  }
}
