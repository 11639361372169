.edit{
  &--panel{
    position: relative;
    width: 100%;
    background: $darkBgColor;
    @include border-radius(8px);
    .form{
      &--holder{
        display: flex;
        label{
          color: $editPanelText;
        }
        input{
          background: $editPanelText;
          border: 2px solid $secondary_color;
          @include border-radius(4px);
          padding:$std-padding;
          outline:none;
          transition: all .2s;
          &:focus{
            border: 2px solid $primary_color;
          }
        }
      }
    }
  }
}
