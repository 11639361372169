.baner{
  width: calc(33.33% - 8px);
  display: inline-block;
  float:left;
  height: calc(50% - 105px);
  background: center;
  text-decoration: none;
  margin:0 4px;
  background-repeat: no-repeat;
  background-color: black;
  font-family: CallunaRegular;
    background-size: auto 128%;
    background-size: auto 150%;
    font-size: 20px;

  &--rekoski{
    background:  #2c57a6;
    background-size: 50% auto;
    background-repeat: no-repeat;

    *{
    color: #fff;
    }

  }

  &:nth-of-type(1), &:nth-of-type(4) {
    margin-left:0;
  }

  &:nth-of-type(3), &:nth-of-type(6) {
    margin-right:0;
  }

  &:nth-of-type(4){
    top:105px;
    position: relative;

    @include mobile{
      top:0;
    }

    @include tablet{
      top:0;
    }
  }
  &:nth-of-type(5){
    top:105px;
    position: relative;
    @include mobile{
      top:0;
    }
    @include tablet{
      top:0;
    }
  }
  &:nth-of-type(6){
    top:105px;
    position: relative;
    @include mobile{
      top:0;
    }
    @include tablet{
      top:0;
    }

  }

  @include laptop{
    width: calc(33.33% - 6px);
    margin:0 3px;
  }
  @include tablet{
    width: calc(50% - 4px);
    margin:0 2px;

  }
  @include mobile{
    width: 100%;
    margin:2px 2px;
  }

  &__container{
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    justify-content: space-between;
    align-items: stretch;

    @include mobile{
      margin-top:0;
    }

  }

  &--subsite{
    height: 0;
    width: 100%;
    background: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 66%;
    margin-top: 100px;

    &:after{
      content: '';
          position: absolute;
          background: url(../_gfx/bg-1.svg) center center;
          width: 100%;
          padding-bottom: 23%;
          bottom: 0;
          background-size: auto 100%;
          left: 0;
          display: table;

    }
  }

  &__title{
    display: flex;
    align-items: center;
    text-align: center;
    height:100%;
    color: #fff;
    text-shadow: 2px 2px 1px #310d00;
    transition: all .2s;
    text-decoration: none;

    background: rgba(0,0,0,0.3);

    &:hover{
      background: rgba(0,0,0,0);

    }

    &__text{
      width: 100%;
    }
  }
}
