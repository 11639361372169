h1{

}
h2{    font-family: CallunaRegular;
font-size: 60px;
}
h3{
  margin-top:0;
  margin-bottom: 20px;
  position: relative;
  @include mobile{
    font-family: CallunaRegular;
  }
}
h4{
  margin-top:0;
  margin-bottom: 15px;
  position: relative;
}
h5{
  @include font-style(lato, 24px, $secondary_color, 800, inherit  )
}
h6{
  @include font-style(lato, 20px, $secondary_color, 800, inherit  )
}

p{
  @include font-style(lato, 20px, $secondary_color, 400, inherit  )
  position: relative;
  margin-top:0;
  line-height: 32px;

  @include mobile{
    @include font-style(lato, 18px, $secondary_color, 400, inherit  )
  }
}
ul, ol{
  line-height: 32px;
}

.block--link-oferta{
  text-align: center;
  a{
      background: #fff;
      width: 100%;
      z-index: 10;
      margin-top:0;
      margin-bottom: 0px;
      display: inline-block;
      position: relative;
      transition: all .2s;
      padding: 16px 0;
      box-sizing: border-box;

      @include mobile{

      }
    &:hover{
      color:$secondary_color_page;
    }
  }
}

.before-decoration a::before{
  content: '';
  width:65px;
  height:58px;
  display: inline-block;
  position:absolute;
  top:-8px;
  left:-75px;
}
.after-decoration a::after{
  content: '';
  width:65px;
  height:58px;
  display: inline-block;
  position:absolute;
  top:-8px;
  right:-75px;
}

.container--flex{
  display: flex;
  background: #542015;
  @include tablet{
  flex-wrap: wrap;
}
@include mobile{
flex-wrap: wrap;
}


}


.historia-1{
  background: #cc9e62;
      color: #fff;
      padding-left: calc((100% - 1200px) / 2);
      box-sizing: border-box;
      padding-bottom: 36%;
      background-image: url(../_gfx/subsite/image-6.jpg);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 100% auto;
      display: table;
      padding-right: 50px;

      @include laptop{
        padding-right: 20px;
        padding-left: 20px;
      }

      @include tablet{
        background: #cc9e62;
        padding: 50px 20px;
        width: 100%;
      }
      @include mobile{
        background: #cc9e62;
        padding: 50px 20px;
        width: 100%;
      }

    p{
      color: #fff;
    }
}

.historia-2{
  background: #542015;
    color: #fff;
    padding-right: calc((100% - 1200px) / 2);
    padding-left: 50px;

    box-sizing: border-box;
    padding-top: 60%;
    background-image: url(../_gfx/subsite/image-7.jpg);
    background-position: center top;
    background-repeat: no-repeat;
display: table;
    background-size: 100% auto;
    @include laptop{
      padding-right: 20px;
      padding-left: 20px;
    }
    @include tablet{
      background: #542015;
      padding: 50px 20px;
      width: 100%;
    }
    @include mobile{
      background: #542015;
      padding: 50px 20px;
      width: 100%;
    }

    p{
      color: #fff;
    }
}

.powolanie{

  &-1{
    @extend .historia-1;
    padding-bottom: 64%;
    background-image: url(../_gfx/powolanie/image-6.jpg);
    display: table;

    @include laptop{
      padding-right: 20px;
      padding-left: 20px;
    }
    @include tablet{
      background: #cc9e62;
      padding: 50px 20px;
      width: 100%;
    }
    @include mobile{
      background: #cc9e62;
      padding: 50px 20px;
      width: 100%;
    }

    &-1{
      padding-top: 0;
      background: none;
      padding-bottom: 0;
      background: url(../_gfx/mlodziez/image-4.jpg) left center #2c57a6;
      background-size: cover;
      background-repeat: no-repeat;
      @include mobile{
        display: none;

      }
      @include tablet{
        display: none;
      }
    }
  }

  &-2{
    @extend .historia-2;
    padding-top: 53%;
    padding-bottom: 36%;
    background-image: url(../_gfx/powolanie/image-5.jpg), url(../_gfx/powolanie/image-7.jpg);
    background-position: center top, center bottom;
    display: table;

    @include laptop{
      padding-right: 20px;
      padding-left: 20px;
    }
    @include tablet{
      background: #542015;
      padding: 50px 20px;
      width: 100%;
    }
    @include mobile{
      background: #542015;
      padding: 50px 20px;
      width: 100%;
    }

    &-1{
      padding-top: 0;
      background: none;
      padding-bottom: 0;}

  }
}

.jestem{
  &-1{
    @extend .historia-1;
    padding-bottom: 75%;
    background-image: url(../_gfx/jestem/image-3.jpg);
    background-color: #542015;
    display: table;
  }

  &-2{
    @extend .historia-2;
    padding-top: 83%;
    padding-bottom: 0%;
    background-image: url(../_gfx/jestem/image-2.jpg);
    background-position: center top;
    background-color: #cc9e62;
    display: table;
  }
}

.youtube{
  width: 100%;
  position: relative;
  height: 0;
  padding-top: 75%;

  iframe{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
  }
}


.baner--download{
  background: url(../_gfx/subsite/image-9.jpg) no-repeat center;
  background-size: cover;font-family: CallunaRegular;

  .h3--big{
    font-size:60px;
    color:#fff;
    width: 100%;
    text-align: center;
        display: inline-block;
        @include mobile{
          font-size: 26px;
        }
  }
  .link--big{
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size:46px;
    color:#fff;
    border: 2px solid #fff;
    padding: 30px 0;
    margin-bottom: 30px;
    transition: all .2s;
    @include mobile{
      font-size: 20px;
    }


    &:hover{
      opacity: 0.5;
    }
  }
}
