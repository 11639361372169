.tree{
  &-container{
    position: fixed;
    float: left;
    display: inline-block;
    width: 350px;
    top: 96px;
    left: -310px;
    @include mobile{
      left:-350px;
    }
    overflow: hidden;
    bottom: 0px;
    background-color: #1d2127;
    z-index: 100;
    transition: all .2s;
    .holder_1_1{
      max-height:38px;
      transition: all .2s, max-height 0.5s;
      .icons{
        position: absolute;
        left:300px;
        top:10px;
      }
    }

    &.active, &:hover{
      width:350px;
      left:0;
      .holder_1_1{
        height:auto;
        max-height:2000px;
        .icons{
          position: absolute;
          left:300px;
          top:10px;
        }
      }
    }
  }
}
.position3{
  @include tablet{
    display: none;
  }
  @include mobile{
    display: none;
  }

}
.top_menu{
  .logo{
    @include tablet{
      width: 100%;
height: 23px;
display: inline-block;
float: left;
background-repeat: no-repeat;
background-size: auto 100%;
background-position: center;
margin: 0;
margin-top: 13px;
margin-bottom: 12px;
    }
    @include mobile{
      width: 100%;
height: 23px;
display: inline-block;
float: left;
background-repeat: no-repeat;
background-size: auto 100%;
background-position: center;
margin: 0;
margin-top: 13px;
margin-bottom: 12px;
    }
  }
}
.right_cont{
  @include tablet{
    right:-150px;
    position: relative;
  }
  @include mobile{
    right:-150px;
    position: relative;
  }
}
.drzewo a{transition:all .2s;}
.drzewo a.dodaj_podstrone{top:0;transition:all .2s;position: relative;}

.drzewo a:hover{top:-48px;}
.drzewo a.dodaj_podstrone:hover{top:-48px;}
