.mobile{

  &__menu{
    position: fixed;
    background: #381e0f;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    overflow: hidden;
    max-height: 0;
    top: -41px;
    transition: all .3s;

    &.active{
      top:0;
      height: auto;
      max-height: 1000px;
    }

    a{
      color: #fff;
      width: 100%;
      display: inline-block;
      float: left;
      text-align: left;
      padding: 10px 0;
    }
    p{
      text-align: left;
      color: #fff;
    }

    @include desktop{
      display: none;
    }
  }
}
