body{
  margin:0;padding:0;text-align: center;
  font-family: lato;
}
.container{
  padding:83px 0;
}
.row{
  max-width: 1200px;
  text-align: left;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: -5px;
  .col{
    &-sm{display: inline-block;
      @for $i from 1 through $grid-columns {

        &-#{$i} { width: #{$i/12*100%}  ;
        padding: $padding;
        display: inline-block;
        float: left;
        box-sizing: border-box;
        &.no-padding{padding: 0;}
      }

    }
  }
}
}

.no-padding-top{padding-top:0;}
.no-padding-bottom{padding-bottom:0;}
.right{float: right !important;
  position: relative;
  right:270px;
  &--absolute{
    position: absolute;
    right:0;
  }
}

.subpage{
  h3{
    font-size: 28px;
  }
  .row{
    &.decorated{
      position: relative;
      border-right:3px solid #e9dfdf;
      &::before{
        position: absolute;
        top:40px;
        right:-13px;
        content: '';
        width: 20px;
        height: 20px;
        @include border-radius(50%);
        border: solid 2px #fff;
        background: #d8a5a5;
        opacity: 1;
        z-index: 13;

      }
      &::after{
        position: absolute;
        top:50px;
        right:10px;
        content: '';
        width: 200px;
        height: 4px;
        background: #d8a5a5;
        opacity: 1;
      }
    }
    .col{
      &-sm{display: inline-block;
        @for $i from 1 through $grid-columns {

          &-#{$i} { width: #{$i/12*100%}  ;
          padding: 0 15px;
          display: inline-block;
          float: left;
          box-sizing: border-box;
          &.no-padding{padding: 0;}
        }
      }
    }
  }



  hr{
    margin-bottom: 15px;
    width: 202px;
    float: left;
    display: inline-block;
  }
  p{
    width: 100%;
    display: inline-block;
  }
}

}



@include desktop{
  .large{
        display: inline-block;
        @for $i from 1 through $grid-columns {
          &-#{$i} { width: #{$i/12*100%}  ;
          padding: 0 15px;
          display: inline-block;
          float: left;
          box-sizing: border-box;
          &.no-padding{padding: 0;}
        }
      }
    }
    .left-large{
          display: inline-block;
          @for $i from 1 through $grid-columns {
            &-#{$i} { margin-left: #{$i/12*100%}  ;

          }
        }
      }
      .right-large{
            display: inline-block;
            @for $i from 1 through $grid-columns {
              &-#{$i} { margin-right: #{$i/12*100%}  ;

            }
          }
        }


  .large-offset{
        display: inline-block;
        @for $i from 1 through $offset-steps {
          &-m#{$i}0 {
          top: -#{$i*10px}  ;
          position: relative;
        }
      }
    }

    .large-offset{
          display: inline-block;
          @for $i from 1 through $offset-steps {
            &-p#{$i}0 {
            top: #{$i*10px}  ;
            position: relative;
          }
        }
      }
}

@include tablet{
.medium{
      display: inline-block;
      @for $i from 1 through $grid-columns {
        &-#{$i} { width: #{$i/12*100%}  ;
        padding: 0 15px;
        display: inline-block;
        float: left;
        box-sizing: border-box;
        &.no-padding{padding: 0;}
      }
    }
  }

  .left-medium{
        display: inline-block;
        @for $i from 1 through $grid-columns {
          &-#{$i} { margin-left: #{$i/12*100%}  ;

        }
      }
    }
    .right-medium{
          display: inline-block;
          @for $i from 1 through $grid-columns {
            &-#{$i} { margin-right: #{$i/12*100%}  ;

          }
        }
      }
      .medium-offset{
            display: inline-block;
            @for $i from 1 through $offset-steps {
              &-m#{$i}0 {
              top: -#{$i*10px}  ;
              position: relative;
            }
          }
        }

        .medium-offset{
              display: inline-block;
              @for $i from 1 through $offset-steps {
                &-p#{$i}0 {
                top: #{$i*10px}  ;
                position: relative;
              }
            }
          }


}

@include mobile{
.small{
      display: inline-block;
      @for $i from 1 through $grid-columns {
        &-#{$i} { width: #{$i/12*100%}  ;
        padding: 0 15px;
        display: inline-block;
        float: left;
        box-sizing: border-box;
        &.no-padding{padding: 0;}
      }
    }
  }

  .left-small{
        display: inline-block;
        @for $i from 1 through $grid-columns {
          &-#{$i} { margin-left: #{$i/12*100%}  ;

        }
      }
    }
    .right-small{
          display: inline-block;
          @for $i from 1 through $grid-columns {
            &-#{$i} { margin-right: #{$i/12*100%}  ;

          }
        }
      }
      .small-offset{
            display: inline-block;
            @for $i from 1 through $offset-steps {
              &-m#{$i}0 {
              top: -#{$i*10px}  ;
              position: relative;
            }
          }
        }

        .small-offset{
              display: inline-block;
              @for $i from 1 through $offset-steps {
                &-p#{$i}0 {
                top: #{$i*10px}  ;
                position: relative;
              }
            }
          }


}
