.logo{
  background: url(../_gfx/logo.png) no-repeat center center;
  width: 246px;
  height: 394px;
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-indent: -9999px;
  transition: .2s;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(calc(-50% - 480px));
  transition: all .3s;
  box-shadow: 0px 1px 22px 4px rgba(0,0,0,0.3);

  h1{
    font-family: CallunaRegular;
  }

  &:hover{
    opacity: 1;
  }

  @include laptop{
    left: 0%;
    transform: translateX(0);
    width: 100%;
    height: 200px;
    background-size: auto 80%;
  }
  @include tablet{
    left: 0%;
    transform: translateX(0);
    width: 100%;
    height: 200px;
    background-size: auto 80%;
  }
  @include mobile{
    left: 0%;
    transform: translateX(0);
    width: 100%;
    height: 350px;
    background-size: auto 80%;
  }
}

.scrolled .logo{
  top: -225px;
  padding-bottom: 30px;
}
