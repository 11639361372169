nav{
  display: inline-block;
  float: right;
  clear: both;
  right: -22px;
  position: relative;
  transition:all .2s;
  top: -5px;
  @include mobile{
    display: inline-block;
float: right;
clear: both;
right: 0;
position: relative;
top: 0;
width: 100%;
border-top: solid 1px #ccc;
margin-top: 21px;
border-bottom: 6px solid #ccc;
padding-bottom: 10px;
  }
  ul{
    padding: 0;
    display: inline-block;
    margin: 0;
    @include mobile{
      width: 100%;
    }
    &.navbar-nav{
    }
    li.menuItem{
      padding: 0;
      display: inline-block;
      position: relative;
      max-height: 40px;
      @include mobile{
        width: 100%;
        text-align: center;
      }
      .menu__link-std{
        @include mobile{
          width:100%;
          padding:0;
          margin:15px 0;
        }
      }
      ul
      {
        position: absolute;
        background: $primary_color;
        padding: 10px 0;
        top:60px;
        opacity: 0;
        transition: all .3s;
        pointer-events: none;
        left:50%;
        transform: translateX(-50%);
        width: 240px;
        color:#fff;
        @include border-radius(0px);
        @include box-shadow(0px,2px,8px,rgba(0,0,0,0), false)
        &::before
        {
          width: 20px;
          height:20px;
          transform:translateX(-50%) rotateZ(-45deg);
          content: '';
          display: inline-block;
          position: absolute;
          background: $primary_color;
          top:-10px;
          left:50%;
          @include box-shadow(4px,-4px,6px,rgba(0,0,0,0.2), false)
        }
      li{width: 100%;
        height: auto;
        display: inline-block;
        max-height: 100px;
        overflow: visible;
        a{
          color:#fff !important;
          text-transform: uppercase;
          width: 100% !important;
          text-align: center;
          display: inline-block;
          max-width: 100%;
          box-sizing: border-box;
          &:hover
            {
            background: #fff;
            color: $primary_color !important;
            }
          }
        }
      }
      float: left;
      transition: all .2s;

    }
  }
}
footer{
  nav{
    display: inline-block;
    float: right;
    clear: both;
    right: 0px;
    position: relative;
    top: -5px;
    margin-top:30px;
    margin-bottom:30px;
    border: 0;
    ul{
      padding: 0;
      display: inline-block;
      margin: 0;
      &.navbar-nav{
      }
      li.menuItem{
        padding: 0;
        display: inline-block;
        position: relative;
        max-height: 40px;
        ul
        {
          position: absolute;
          background: $primary_color;
          padding: 10px 0;
          top:60px;
          opacity: 0;
          transition: all .3s;
          pointer-events: none;
          left:50%;
          transform: translateX(-50%);
          width: 240px;
          color:#fff;
          @include border-radius(0px);
          @include box-shadow(0px,2px,8px,rgba(0,0,0,0), false)
          &::before
          {
            width: 20px;
            height:20px;
            transform:translateX(-50%) rotateZ(-45deg);
            content: '';
            display: inline-block;
            position: absolute;
            background: $primary_color;
            top:-10px;
            left:50%;
            @include box-shadow(4px,-4px,6px,rgba(0,0,0,0.2), false)
          }
        li{width: 100%;
          height: auto;
          display: inline-block;
          max-height: 100px;
          overflow: visible;
          a{
            color:#fff !important;
            text-transform: uppercase;
            width: 100% !important;
            text-align: center;
            display: inline-block;
            max-width: 100%;
            box-sizing: border-box;
            font-size: 22px;
            color:#fff;
            &:hover
              {
              background: #fff;
              color: $primary_color !important;
              }
            }
          }
        }
        float: left;
        transition: all .2s;

      }
    }
  }
}
.nav-icon{
  display: none;

  &.active{
    div{
      background: #fff;
    }
  }
  @include laptop{
    display: inline-block;
    float: right;
    width: 22px;
    margin-top: 10px;
    z-index: 10000;
    position: fixed;
    top: 10px;
    right: 10px;
  }
  @include tablet{
    display: inline-block;
    float: right;
    width: 22px;
    margin-top: 10px;
    z-index: 10000;
    position: fixed;
    top: 10px;
    right: 10px;
  }
  @include mobile{
    display: inline-block;
    float: right;
    width: 22px;
    margin-top: 10px;
    z-index: 10000;
    position: fixed;
    top: 10px;
    right: 10px;
  }


  div{
    display: inline-block;
    background: #442618;
    width: 100%;
    height: 3px;
    float: left;
    margin-bottom: 5px;

  }
}
.scrolled{
  .active{
    nav{
      @include mobile{
        margin-top: -40px;
      }
    }
  }
}




.recaptcha{
  transform: scale(0.67);
  -webkit-transform: scale(0.67);
  -moz-transform: scale(0.67);
  position: absolute;
  top: 13px;
  left: -40px;
}
