.block{
  &--paralax{
    width:1920px;
    height:700px;
    position: relative;
    background-position: center;
    background-size:auto 100% ;
    margin-left:-360px;
    margin-right:-360px;
    @include tablet{
      padding-top:30%;
      padding-bottom:30%;
      margin:0;
      height:auto;
      width: 100%;
    }
    @include mobile{
      padding-top:60%;
      padding-bottom:60%;
      margin:0;
      height:auto;
      width: 100%;
    }
    display: inline-block;


    &.before{
      &-white{
        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height:20px;
          top:0;
        }
      }
      &-black{
        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height:20px;
          top:0;
        }
      }
    }
    &.after{
      &-white{
        &::after{
          content: '';
          position: absolute;
          width: 100%;
          height:20px;
          bottom:0;
        }
      }
      &-black{
        &::after{
          content: '';
          position: absolute;
          width: 100%;
          height:20px;
          bottom:0;
        }
      }
    }
    &:hover{
      .system-overlay{
        opacity:.8;
        p{
          margin:20px;
        }
      }
    }
  }
}

.content--center_center{
  .if-center-content{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h3{
      color: #fff;
    }
    p{
      color:#fff;
    }
  }

}
