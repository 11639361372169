.system{
  &-overlay{
    opacity:0;
    left:20px;
    top:40px;
    position: absolute;
    padding: $padding;
    background: #fff;
    @include border-radius(10px);
    transition: all .2s;
    p{
      margin:20px;
    }
  }

}
body{
  background-color:#fff;
}
