.hidden{

  &--desktop{
    @include desktop{
      display: none;
    }
  }

  &--laptop{
    @include laptop{
      display: none;
    }
  }

  &--tablet{
    @include tablet{
      display: none;
    }
  }

  &--mobile{
    @include mobile{
      display: none;
    }
  }

}
