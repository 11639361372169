.slide-up{
  position: relative;
  transform: translateY(300px);
  opacity: 0;
  transition: all 1s ;
}
.slide-up.come-in{
  transform: translateY(0px);
  opacity: 1;
}


.slide-up p{
  position: relative;
  transform: translateY(300px);
  opacity: 0;

  &:nth-of-type(1){
    transition: transform 1s, opacity 1s ;
  }
  &:nth-of-type(2){
    transition: transform 1s .3s, opacity 1s .3s;
  }
  &:nth-of-type(3){
    transition: transform 1s .2s, opacity 1s .2s;
  }
  &:nth-of-type(4){
    transition: transform 1s .4s, opacity 1s .4s;
  }
  &:nth-of-type(5){
    transition: transform 1s .3s, opacity 1s .3s;
  }

}

.slide-up.come-in p{
  transform: translateY(0px);
  opacity: 1;
}
