.atribute{
  &--flex{
    display: flex !important;
    margin: 0 auto;
    &1{
      flex: 1;
    }
    &column{
      flex: column;
    }
  }
  &--rel{
    position: relative;
  }
  &--full{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  &--right{
    float: right;
  }

}
