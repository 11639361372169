a{
  color: $brown;
  text-decoration: none;
}

.link{

  &__special{
    background: #fffcf5;
    border: 1px solid #4e433d;
    box-shadow: 0 0 0 10px #fffcf5;
    color: #4e433d;
    padding: 20px;
    text-decoration: none;

    @include laptop{top:30px;position:relative;}
    @include tablet{top:30px;position:relative;}
    @include mobile{top:30px;position:relative;}
  }

  &__contact{
    color: $brown;
    padding: 10px 10px 10px 100px;
    border:1px solid $brown;
    text-decoration: none;
    font-size: 36px;
    position: relative;
    font-family: CallunaRegular;
    @include laptop{color: #a86e35;
        padding: 4px 10px 4px 65px;
        border: 1px solid #a86e35;
        text-decoration: none;
        font-size: 23px;
        position: relative;
        font-family: CallunaRegular;
        order: 2;
        margin: 20px;}
    @include tablet{color: #a86e35;
        padding: 4px 10px 4px 65px;
        border: 1px solid #a86e35;
        text-decoration: none;
        font-size: 23px;
        position: relative;
        font-family: CallunaRegular;
        order: 2;
        margin: 20px;}
@include mobile{color: #a86e35;
    padding: 4px 10px 4px 65px;
    border: 1px solid #a86e35;
    text-decoration: none;
    font-size: 23px;
    position: relative;
    font-family: CallunaRegular;
    order: 2;
    margin: 20px;}


    &:before{
      content: '';
      position: absolute;
      height: 83px;
      width: 74px;
      background: url(../_gfx/logo_icon.png);
      top: -7px;
      left: 10px;
      @include laptop{
        content: '';
  position: absolute;
  height: 58px;
  width: 45px;
  background: url(../_gfx/logo_icon.png);
  top: -7px;
  left: 10px;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;    }
    @include tablet{
      content: '';
position: absolute;
height: 58px;
width: 45px;
background: url(../_gfx/logo_icon.png);
top: -7px;
left: 10px;
background-size: auto 100%;
background-position: center;
background-repeat: no-repeat;
  }
  @include mobile{
    content: '';
position: absolute;
height: 58px;
width: 45px;
background: url(../_gfx/logo_icon.png);
top: -7px;
left: 10px;
background-size: auto 100%;
background-position: center;
background-repeat: no-repeat;
  }


    }
  }

  &--main-menu{
    font-size: 21px;
    color: $menu-link-color;
    margin-left:30px;
    float: left;
    transition: all .2s;

    &:hover{
      opacity: 0.6;
    }

    &--active{
      @extend .link--main-menu;
      color: $menu-link-color-active;
      @include mobile{
        width: 100%;
        text-align: center;
        padding: 10px 0;
      }

      @include tablet{
        width: 100%;
        text-align: center;
        padding: 10px 0;
      }

            @include laptop{
              width: 100%;
              text-align: center;
              padding: 10px 0;
            }
    }

    @include mobile{
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }

    @include tablet{
      width: 100%;
      text-align: center;
      padding: 10px 0;
    }

        @include laptop{
          width: 100%;
          text-align: center;
          padding: 10px 0;
        }
  }
}
