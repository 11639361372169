.section{
  display: inline-block;
  width: 100%;

  &__container{
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    padding: 50px calc((100% - 1200px) / 2) 80px calc((100% - 1200px) / 2);
  }
  &__gallery{
    img{
      width: 100%;
      margin-bottom: 20px;
    }
  }

  &--mlodziez{
    background: #542015;

    *{
      color: #fff;
    }
  }

  &--background{

    position: relative;
    overflow: hidden;

    &:before{
      content: '';
      position: absolute;
      top:-200px;
      background: url(../_gfx/bg-2.svg) center center;
      width: 100%;
      padding-top: 150%;
      top: 0;
      background-size: auto 100%;
      left: 0;
      display: table;
      z-index: -1;
    }
  }
}



.video-background {
  background: #000;
  top: 0; right: 0; bottom: 0; left: 0;
  padding-top:56%;
  position: relative;
  }
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
}
