.footer{
  background: $footer-background;
  padding: 50px calc((100% - 1200px) / 2) 40px calc((100% - 1200px) / 2);

  &__menu{
    display: flex;
    justify-content: space-between;
    text-align: center;

    @include tablet{
      flex-direction: column;
    }

    @include mobile{
      flex-direction: column;
    }

    &__link{
      font-size: 21px;
      color: $white;
      margin: 0 15px;
    }

    &--social-panel{
      margin-top:40px;
      text-align: center;

      &--facebook{
        width: 36px;
        height: 45px;
       display: inline-block;
               background: url(../_gfx/subsite/fb_icon.jpg);
      }

      &--youtube{
        width: 54px;
        height: 45px;
        display: inline-block;
        background: url(../_gfx/subsite/yt_icon.jpg);
      }
    }
  }

  &--credentials{
    padding: 0 20px;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    color: #fff;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

      a{
        color: #fff;
        font-weight: 800;
        transition: opacity .2s;

        &:hover{
          opacity: .5 ;
        }
      }

    @include tablet{
      flex-direction: column;
    }
    @include mobile{
      flex-direction: column;
    }

  }
}
