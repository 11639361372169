@font-face {
    font-family: CallunaBlack;
    src: url(_fonts/Calluna-Black.woff);
}
@font-face {
    font-family: CallunaBold;
    src: url(_fonts/Calluna-Bold.woff);
}
@font-face {
    font-family: CallunaBoldIt;
    src: url(_fonts/Calluna-BoldIt.woff);
}
@font-face {
    font-family: CallunaIt;
    src: url(_fonts/Calluna-It.woff);
}
@font-face {
    font-family: CallunaLight;
    src: url(_fonts/Calluna-Light.woff);
}
@font-face {
    font-family: CallunaRegular;
    src: url(_fonts/Calluna-Regular.woff);
}
@font-face {
    font-family: CallunaSemibold;
    src: url(_fonts/Calluna-Semibold.woff);
}
@font-face {
    font-family: CallunaSemiboldIt;
    src: url(_fonts/Calluna-SemiboldIt.woff);
}
@font-face {
    font-family: PlayfairDisplayBlack;
    src: url(_fonts/PlayfairDisplay-Black.woff);
}
@font-face {
    font-family: PlayfairDisplayBlackItalic;
    src: url(_fonts/PlayfairDisplay-BlackItalic.woff);
}
@font-face {
    font-family: PlayfairDisplayBold;
    src: url(_fonts/PlayfairDisplay-Bold.woff);
}
@font-face {
    font-family: PlayfairDisplayBoldItalic;
    src: url(_fonts/PlayfairDisplay-BoldItalic.woff);
}
@font-face {
    font-family: PlayfairDisplayItalic;
    src: url(_fonts/PlayfairDisplay-Italic.woff);
}
@font-face {
    font-family: PlayfairDisplayRegular;
    src: url(_fonts/PlayfairDisplay-Regular.woff);
}



$mainColor : #163b1a;
$menuTopLink : #6b6b6b;
$up : uppercase;

$standartWidth: 1200px;
$standardMargin: 30px;



@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin font-style($font, $font-size, $font-color, $font-weight ,$case) {
  font-family: $font;
  font-size: $font-size;
  color: $font-color;
  font-weight:  $font-weight;
  text-transform: $case;
}

@mixin box-sizing($box){
  -webkit-box-sizing: $box;
  -moz-box-sizing: $box;
  box-sizing: $box;
}




/* clean up */

/* variable screnn sizes */

$tablet-width: 480px;
$laptop-width: 864px;
$desktop-width: 1124px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}



@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

/* text style */

/* shadow */
@mixin box-shadow($top: 2px, $left: 2px, $blur: 8px, $color: rgba(0,0,0,0.4), $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

/* border */
@mixin border-all($color: $primary_color, $width: 3px, $style: solid ) {
  border-width: $width;
  border-style: $style;
  border-color: $color;
}

/* shadow */
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}


/* GRID */

@for $i from 1 through 12 {
    $width: percentage($i / 12);

    @include desktop{
      .large-#{$i} {
          width: $width;
          display: inline-block;
          float: left;
          text-align: left;
          &-left{
            margin-left: $width;
          }
      }
      .desktop-#{$i} {
          width: $width;
          display: inline-block;
          float: left;
          padding: 0 20px;
          box-sizing: border-box;
          position: relative;
          text-align: left;

          img{
            max-width: 100%;
          }
      }
    }
    @include laptop{
      .laptop-#{$i} {
          width: $width;
          display: inline-block;
          float: left;
          padding: 0 20px;
    box-sizing: border-box;
          position: relative;
          text-align: left;
           }
    }
    @include tablet{
      .tablet-#{$i} {
          width: $width;
          display: inline-block;
          float: left;
          padding: 0 20px;
    box-sizing: border-box;
          position: relative;
          text-align: left;
      }
    }
    @include mobile{
      .phone-#{$i} {
          width: $width;
          display: inline-block;
          float: left;
          padding: 0 20px;
    box-sizing: border-box;
          position: relative;
          text-align: left;
      }
    }
}
