.container{

  &--facebook{
    position: relative;
    height: 500px;

    .fb-page{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__flex{
    display: flex !important;

    @include laptop{
      display: inline-block !important;
    }
    @include tablet{
      display: inline-block !important;
    }
    @include mobile{
      display: inline-block !important;
    }

    &--center{
      align-items: center;
      justify-content: center;
    }
  }

  &--normal-width{
    padding: 50px calc((100% - 1200px) / 2) 80px calc((100% - 1200px) / 2);
    display: flex;
    flex-wrap: wrap;
  }
  &--max-width{
    padding: 50px calc((100% - 1600px) / 2) 80px calc((100% - 1600px) / 2);
    display: flex;
    flex-wrap: wrap;
  }

  &--inline{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-bottom: 60px;
    @include tablet{
      flex-wrap: wrap;

    }
    @include mobile{
      flex-wrap: wrap;

    }

  }

  &--logo{
    display: flex;
    align-items: center;

    @include laptop{
      order: 1;
    }
    @include tablet{
      order: 1;

    }
    @include mobile{
      order: 1;

    }

    img{
      margin-right: 30px;

      @include laptop{
        margin-right: 0;
        transform: scale(0.8);
      }
      @include tablet{
        margin-right: 0;
        transform: scale(0.8);
      }
      @include mobile{
        margin-right: 0;
        transform: scale(0.8);
      }


    }

    h1{
      color: $header;
      font-size: 34px;
      font-weight: bold;
      font-family: CallunaRegular;

      @include laptop{
        color: #643517;
        font-size: 17px;
        font-weight: bold;
        font-family: CallunaRegular;
      }
      @include tablet{
        color: #643517;
        font-size: 17px;
        font-weight: bold;
        font-family: CallunaRegular;
      }
      @include mobile{
        color: #643517;
        font-size: 17px;
        font-weight: bold;
        font-family: CallunaRegular;
      }

      span{
        font-size: 30px;
        font-weight: 400;

        @include laptop{
          font-size: 13px;
          font-weight: 400;
        }
        @include tablet{
          font-size: 13px;
          font-weight: 400;
        }
        @include mobile{
          font-size: 13px;
          font-weight: 400;
        }

      }
    }
  }
}


.see-also{
  background: #f1edea;

  a{
    font-family: CallunaRegular;
    color:#666678;
    font-size: 40px;
  }

  &--module{
    margin-bottom: 50px;
  }

  &--module-2{
    margin-bottom: 50px;
  }

}
