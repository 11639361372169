.news{
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: #7e574e;
  color: #fff;
  font-size: 20px;
  font-family: CallunaRegular;
  display: inline-block;
  margin-bottom: 30px;
transition: all .2s;

  &:hover{
    background: darken(#7e574e, 10%);
  }

  &__date{
    margin-right:30px;
  }

  &__title{

  }

  &--active{
    border: solid 2px #7e574e;
    background: #fff !important;
    color: #7e574e !important;
  }
}

.news--subsite{
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: #7e574e;
  color: #fff;
  font-size: 20px;
  font-family: CallunaRegular;
  display: inline-block;
  margin-bottom: 30px;

  &__date{
    margin-right:30px;
  }

  &__title{

  }
}
