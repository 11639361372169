
header{
  position: fixed;
  left:0;
  top:50%;
  transform: translateY(-50%);
  width: 100%;
  background: $white;
  z-index: $header-zindex;
  transition: all .4s;
  box-sizing: border-box;
  padding: 20px 0;
  max-height: 210px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @include mobile{
    position: fixed;
left: 0;
top: 50%;
-ms-transform: translateY(-50%);
transform: translateY(-50%);
width: 100%;
background: #fff;
z-index: 1000;
transition: all .4s;
box-sizing: border-box;
padding: 20px 0;
max-height: 210px;
overflow: hidden;
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
-ms-flex-pack: distribute;
justify-content: space-around;
flex-wrap: wrap;
height: auto;
max-height: 1000px;
  }
  @include tablet{
    position: fixed;
  left: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  background: #fff;
  z-index: 1000;
  transition: all .4s;
  box-sizing: border-box;
  padding: 20px 0;
  max-height: 210px;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  flex-wrap: wrap;
  height: auto;
  max-height: 1000px;
  }
  a{
    transition: all .2s;

    &:hover{
      opacity: 0.6
    }
  }

  @include mobile{
    top:0;
    transform: none;
    position: relative;
  }

  @include tablet{
    top:0;
    transform: none;
    position: relative;
  }

  &.scrolled{
  }

  &.header--subsite{
    position: fixed;
    top:0;
    height:80px;
    transform: none;

    @include mobile{
    position: fixed;
    top: 0;
    height: 100vh;
    -ms-transform: none;
    transform: none;
    display: inline-block;
    max-height: 75px;

    &.active{max-height: 100vh;}
  }




  @include tablet{
  position: fixed;
  top: 0;
  height: 100vh;
  -ms-transform: none;
  transform: none;
  display: inline-block;
  max-height: 80px;

  &.active{max-height: 100vh;}
}
@include laptop{
position: fixed;
top: 0;
height: 100vh;
-ms-transform: none;
transform: none;
display: inline-block;
max-height: 100px;

&.active{max-height: 100vh;}

}
  }
  .header{

    &--left{
      float: left;
      margin-left:20px;

      @include laptop{
        float: left;
margin-left: 0;
width: 100%;
text-align: left;
padding: 0 20px 0 20px;
display: inline-block;
text-align: center;
box-sizing: border-box;
      }

      @include mobile{
        float: left;
margin-left: 0;
    width: calc(100% - 67px);
text-align: left;
padding: 0 20px 0 20px;
display: inline-block;
text-align: center;
box-sizing: border-box;
      }

      @include tablet{
        float: left;
margin-left: 0;
width: 100%;
text-align: left;
padding: 0 20px 0 20px;
display: inline-block;
text-align: center;
box-sizing: border-box;
      }
    }
    &--right{
      float: right;
      display: inline-block;
      margin-right:20px;

      @include mobile{
      float: right;
      display: inline-block;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      height: calc(100vh - 110px);
      justify-content: space-evenly;
      align-items: center;
      }

      @include laptop{
      float: right;
      display: inline-block;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      height: calc(100vh - 110px);
      justify-content: space-evenly;
      align-items: center;

        a{
          position: relative;
          &:after{
            content: '';
            position: absolute;
            width: 13px;
            height: 2px;
            background: #a86e35;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      @include tablet{
      float: right;
      display: inline-block;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      height: calc(100vh - 110px);
      justify-content: space-evenly;
      align-items: center;
      }
    }
  }
}
