.phone-12 img{

  @include mobile{
    width: 100%;
    transform: scale(1) !important;
    margin-bottom: 20px;
    position: relative;
    top:0;
  }
}

.tablet-12 img{

  @include tablet{
    margin-left: 100px;
    width: calc(100% - 200px);
    transform: scale(1) !important;
    margin-bottom: 20px;
    position: relative;
    top:0;
  }
}


.zakon{

  &--image{

    &-1{

      @include desktop{
        margin-top: 0px;transform: scale(1.2) translate(20px, 30px) !important;z-index: 1; margin-bottom: 50px;
      }
      @include laptop{
        transform: scale(1.2) translate(44px, 20px) !important;
            z-index: 1;
      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-2{

      @include desktop{
        margin-top: -100px;transform: scale(1.0) translate(-26px, 13px)  !important;
      }
      @include laptop{
transform: scale(0.9) translate(0, 15px) !important;
      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-3{

      @include desktop{
        transform: scale(1.1) translate(32px, 37px) !important;
      }
      @include laptop{
    margin-top: 100px;
      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-4{

      @include desktop{
        transform: translateY(98px) !important;
      }
      @include laptop{

        margin-top: -260px;
        transform: scale(0.7) translate(101px, 74px) !important;
      }
      @include tablet{
      }
      @include mobile{

      }
    }

    &-5{

      @include desktop{

      }
      @include laptop{
        position: sticky;
        top: 100px;
        width: 100%;
        margin-bottom: 30px;
      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-6{

      @include desktop{

      }
      @include laptop{

      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-7{

      @include desktop{

      }
      @include laptop{

      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-8{

      @include desktop{
          margin-top: 96px;
      }
      @include laptop{
        margin-top: 96px;
        width: 100%;
      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-9{

      @include desktop{

      }
      @include laptop{

      }
      @include tablet{

      }
      @include mobile{

      }
    }

    &-10{

      @include desktop{

      }
      @include laptop{

      }
      @include tablet{

      }
      @include mobile{

      }
    }
  }
}

.jestem{

}

.powolanie{

    &--image{

      &-1{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-2{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-3{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-4{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-5{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-6{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-7{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-8{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-9{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-10{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }
    }
  }

.aktualnosci{

}

.mlodziez{

    &--image{

      &-1{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-2{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-3{

        @include desktop{
          transform:scale(.8) !important;
        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-4{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-5{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-6{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-7{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-8{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-9{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }

      &-10{

        @include desktop{

        }
        @include laptop{

        }
        @include tablet{

        }
        @include mobile{

        }
      }
    }
  }


.ministranci{

}

.kontakt{

}
