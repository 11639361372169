html {font-size: 1em;}

body {
  background-color: black;
  font-family: lato;
  font-weight: 400;
  line-height: 1.45;
  color: #442618;
}

p {margin-bottom: 1.3em;}

h1, h2, h3, h4 {
  margin: 1.414em 0 0.5em;
  font-weight: inherit;
  line-height: 1.2;
}

h1 {
  margin-top: 0;
  font-size: 3.998em;
}

h2 {font-size: 2.827em;}

h3 {font-size: 1.999em;

@include laptop{
  font-size: 1.5em;
}
@include tablet{
  font-size: 1.5em;
}
@include mobile{
  font-size: 1.5em;
}
}

h4 {font-size: 1.414em;}

small, .font_small {font-size: 0.707em;}
