.features{

  &__box{
    height: 400px;
    position: relative;
    background-position: center;
    background-size:  100% auto;

    h3{
      width: 100%;
      background: rgba(115, 92, 82, 0.8);
      padding: 29px;
      color: #fff;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}
