.title__block{
  position: relative;
  text-align: left;
  margin-top:60px;
  text-align: center;
}
  hr{
    border: none;
    height:0;
    border-bottom: 3px $primary_color solid;
  }


.desc__block{
  @include font-style(lato, 18px, #fff, 400, inherit  )
  position: relative;
  text-align: left;
  display: inline-block;
  text-align: center;
  width:100%;
}
