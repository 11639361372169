.input{
  &__std{
    &-send{

    }
  }
}
body,html{
  overflow-x: hidden;
}
.custom-bg{
  width:100%;
  padding: 130px 0 30px 0 !important;
  background-size: auto 124% ;
  background-position: center;
}
.subsite{
  @extend .custom-bg;
  display: inline-block;
  width: 100%;
      display: inline-block;
      background-size: 100% auto;
      max-width: 100% !important;
      min-width: 1200px;
      padding:40px 0 !important;
      h2{
        color: #fff;
        display: inline-block;
        float: left;
        width: 100%;
        margin-top: 100px;
        font-size: 60px;
      }
      hr{
        max-width: 200px;
        float: left;
        width: 200px;
      }

}



.text-right{
  text-align: right;
  color: #fff;

}
footer{
  .col-sm-6{
    h2{
      color: $primary_color;
      margin-top: 0;
    }
    p{
      color:#fff;
      strong{
        color: $primary_color;
        font-size: 30px;
        line-height: 40px;
        a{
          color: $primary_color;

        }
      }
    }
  }
}
.form{
  &-group{
    input{
      width:100%;
      padding: 15px;
      color:#fff;
      background: none;
      box-sizing: border-box;
      border: solid 3px #fff;
      margin-bottom: 30px;
      transition: all .5s;
      &:focus{
        border: solid 3px $primary_color;
      }

    }
    textarea{
      @extend input;
      height:200px;
      font-family: 'lato';
    }

  }
}
.submit-button{

  color: #fff;
  float: right;
}

.form-group{
  width: 50%;
      float: left;
      padding: 10px;
      box-sizing: border-box;
      height:75px;
  input{
    width:100%;
    padding: 15px;
    color:#bdbebf;
    background: none;
    box-sizing: border-box;
    border: solid 1px #bdbebf;
    margin-bottom: 10px;
    transition: all .5s;
    background: #fff;
    &:focus{
      border: solid 3px $primary_color;
      color:$primary_color;

    }

  }
  textarea{
    @extend input;
    height:200px;
    font-family: 'lato';
  }
  &:nth-of-type(5){
    width:100%;
        float: left;
        padding: 10px;
        height:235px;
        box-sizing: border-box;


  }

}
