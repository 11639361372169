$primary_color: #98c017;
$secondary_color: #333333;
$white_text: #ffffff;

$white: #fff ;
$brown: #a86e35 ;

$header: #643517;

$editPanelText: #ffffff;
$darkBgColor: #1d2127;
$lightBgColor: #EEE;

$menu-link-color: #16110e;
$menu-link-color-active: #a86e35;
$footer-background: #636267;

$primary_color_page: #74b321;
$secondary_color_page: #888888;
$third_color_page: #000000;

/* padding */
$padding: 0 50px;

$grid-columns: 12;
$offset-steps: 20;
$header-zindex: 1000;

$border: solid 3px $primary_color;

$std-padding: 8px 14px;
