.button--menu{
  width: 22px;
  height: 30px;
  display: inline-block;
  position: fixed;
  top: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @include desktop{
    display: none;
  }

  span{
    background: #442618;
    height: 2px;
    width: 100%;
    border-radius: 1px;
  }
}
