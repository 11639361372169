.topbar{

  &__first-part{
    height: 66px;
    background: #fff;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 19px calc((100% - 1200px) / 2);
    text-align: right;

    p{
      padding-right: 20px;
    }

    @include laptop{display: none}
    @include tablet{display: none}
    @include mobile{display: none}
  }

  &__second-part{
    height: 66px;
    background: #381e0f;
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px calc((100% - 1200px) / 2);
    border-bottom: solid 1px #fff;
      text-align: right;
      @include laptop{display: none}
      @include tablet{display: none}
      @include mobile{display: none}

    a{
      color: #fff;
      text-decoration: none;
      margin: 0 30px;
      position: relative;

      &:after{
        content: '';
        display: block;
        width: 0%;
        height: 2px;
        background: #fff;
        position: absolute;
        bottom: -7px;
        left: 0;
        transition: all .2s;
      }

      &:last-of-type{
        margin: 0 20px 0 30px;
      }

      &:hover{
        &:after{
          width: 100%;
        }
      }
    }
  }

  &__third-part{
    height: 66px;
    box-sizing: border-box;
    padding: 20px calc((100% - 1200px) / 2);
    border-bottom: solid 1px #fff;
      text-align: right;
      @include laptop{display: none}
      @include tablet{display: none}
      @include mobile{display: none}

      a{
        color: #fff;
        text-decoration: none;
        margin: 0 30px;
        position: relative;

        &:after{
          content: '';
          display: block;
          width: 0%;
          height: 2px;
          background: #fff;
          position: absolute;
          bottom: -7px;
          left: 0;
          transition: all .2s;
        }

        &:last-of-type{
          margin: 0 20px 0 30px;
        }

        &:hover{
          &:after{
            width: 100%;
          }
        }
      }

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#381e0f+0,381e0f+100&1+0,0.35+100 */
background: -moz-linear-gradient(top, rgba(56,30,15,1) 0%, rgba(56,30,15,0.35) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(56,30,15,1) 0%,rgba(56,30,15,0.35) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(56,30,15,1) 0%,rgba(56,30,15,0.35) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#381e0f', endColorstr='#59381e0f',GradientType=0 ); /* IE6-9 */
width: 100%;
display: inline-block;
  }
}
