.mainsite
{
  .row
  {
    h2
    {
      margin-top:60px;
      margin-bottom: 40px;
    }
    hr{
      margin-bottom: 35px;
      width: 202px;
      float: left;
      display: inline-block;
    }
    p{
      display: inline-block;
      width: 100%;
    }

    .col-sm-5
    {

    }
    .row-sm-7
    {

    }
  }
}
